.tableContainer {
  table {
    text-align: center;
    .description {
      // width: 100%;
    }
    td {
      vertical-align: middle;
    }
    thead {
      th {
        white-space: nowrap;
        vertical-align: middle;
      }
      th:not(:last-child) {
        border-right: 0.1px solid black;
      }
    }
  }
}
